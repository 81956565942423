$(document).ready(function () {
  var src = $('#video').children('iframe').attr('src');
  $('#video-open').click(function (e) {
    e.preventDefault();
    $('#video')
      .children('iframe')
      .attr('src', src + '&autoplay=1');
    $('#video-wrapper').show();
    $('body').css('overflow', 'hidden');
  });

  $('#video-close,#video-div,#video-wrapper').click(function (e) {
    e.preventDefault();
    $('#video').children('iframe').attr('src', src);
    $('#video-wrapper').hide();
    $('body').css('overflow', 'visible');
  });
});
